<template>
    <div class="speak-sequential">
        <div class="result-title">
            <h2>START领前列·竞巅峰-2022贝坦利优秀病例大赛</h2>
            <i>{{ title }}</i>
            <h3>抢答环节-手速排行榜</h3>
        </div>
        <div class="speak-main">
            <div class="speak-item " :class="who_click==item.player_id?'speak-item-active':''" v-for="(item,index) in array_data">
                <div class="speak-item-no" v-if="index != 0">{{ index + 1 }}</div>
                <div class="speak-item-img" v-if="index == 0">
                    <img src="../../assets/img/baiji/o1.png">
                </div>
                <div class="speak-item-head">
                    <img :src="item.player_img"/>
                </div>
                <div class="speak-item-name">{{ item.player_name }}</div>
                <div class="speak-item-hospital">{{ item.player_hospital_name }}</div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "speak-sequential",
        data() {
            return {
                who_click: 0,
                array_data: [],
                title: '',

            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.$axios.post(this.apiUrl.byAnswerResult,{},{loading:false}).then(res => {
                    this.who_click = res.data.who_click
                    this.array_data = res.data.array_data
                    this.title = res.data.group_name
                    setTimeout(()=> {
                        this.getList()
                    },3000)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .speak-sequential{
        background: url("~@/assets/img/by2022-9.jpg") no-repeat;
        background-size: 100% 100%;
        min-height: 100vh;
        overflow: hidden;
        min-width: 1220px;
        .speak-title{
            font-size: 41px;
            font-family: 'PingFang Regular';
            font-weight: bold;
            color: #464646;
            margin: 0 auto;
            padding: 35px 300px 0;
            text-align: center;
        }
        .speak-main{
            padding: 150px 100px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .speak-item{
                width: 25%;
                text-align: center;
                display: inline-block;
                position: relative;
                margin-bottom: 50px;
                .speak-item-img{
                    position: absolute;
                    left: 50%;
                    top: -50px;
                    margin-left: -45px;
                    img{
                        width: 90px;
                    }
                }
                .speak-item-no{
                    position: absolute;
                    left: 50%;
                    top: -50px;
                    margin-left: -26px;
                    width: 53px;
                    height: 53px;
                    background: #C4C4C4;
                    border-radius: 2px 2px 2px 2px;
                    font-size: 32px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 53px;
                }
                .speak-item-head{
                    width: 166px;
                    height: 166px;
                    /*background: url("../../assets/img/baiji/o3.png") no-repeat;*/
                    background-size: 100% 100%;
                    padding: 2px;
                    margin: 0 auto;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }
                .speak-item-name{
                    font-size: 41px;
                    font-family: 'PingFang-SC-Semibold';
                    font-weight: 800;
                    // color: #464646;
					color: #ffc741;
                }
                .speak-item-hospital{
                    font-size: 29px;
                    font-family: 'PingFang Medium';
                    font-weight: 500;
                    // color: #505050;
					color: #ffc741;
                }
                /*&:first-child{*/
                /*    margin: 0 auto 70px;*/
                /*    display: block;*/
                /*}*/

                &.speak-item-active{
                    .speak-item-head{
                        width: 222px;
                        height: 222px;
                        /*background: url("../../assets/img/baiji/o3.png") no-repeat;*/
                        background-size: 100% 100%;
                        padding: 2px;
                        margin: 0 auto;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                        }
                    }
                    .speak-item-name{
                        color: #ffc741;
                    }
                    .speak-item-hospital{
                        color: #ffc741;
                    }
                }
            }
        }
    }

    .result-title{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        font-family:SimHei;
		color: #ffc741;
        margin-top: 90px;
        h2{font-size: 50px;line-height: 60px;}
        i{font-size: 45px;font-family:'楷体'; font-style:normal;line-height: 55px;}
        h3{
            font-size: 50px;
			font-family:'楷体';
            // text-align: center;
            // font-weight: bold;
            // font-family: PangMenZhengDao;
            // text-transform:uppercase;
            // color:#bf3763;
            // text-shadow:
            //         0px 1px 0px #c0c0c0,
            //         0px 2px 0px #C4C4C4,
            //         0px 3px 0px #CACACA,
            //         0px 4px 0px #CFCFCF,
            //         0px 3px 5px rgba(255, 255, 255, 0.7);
            // letter-spacing:3px;
        }
    }
</style>
